.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .modalContent {
    text-align: center;
    font-size: 18px;
  }
  
  .modal button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    background-color: #EFE7F7;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    border-radius: 5px;
    text-decoration: none;
    font-family: 'Calibri';
    font-size: 18px;
    border: none;
    position: relative;
    margin-top: 30px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    font-size: 16px;
    height: 35px;
}

.modal button:hover {
  background-color: #dcd0eb;
  cursor: pointer;
}