.App {
  text-align: center;
}
.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.quizHeader {
  font-family: 'Calibri';
  font-size: 20px;
  margin-right: -720px;
  color: '#795F80';
}

.innerQuestion {
  background-color: #EFE7F7;
  padding-bottom: 20px;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  font-family: 'Calibri';
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  width: 450px;
  height: 35px;
  margin-right: -75px;
  margin-top: 4px;
  position: relative;

}

.innerAnswer {
  background-color: #EFE7F7;
  padding-bottom: 10px;
  margin-top: 27px;
  padding-top: 35px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  font-family: 'Calibri';
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  margin-left: 10px;
  max-width: 200px;
  height: 46px;
}

.answerContainer {
  display: flex;
  position: relative;
  margin-right: -55px; /* Adjust margin to push to the left */
  justify-content: flex-end; /* Align content to the right */
}


.quizImage {
  margin-right: 5px;
  position: relative;
  margin-top: 20px;
  z-index: -1;
  padding-bottom: 145px;
  padding-top: 20px;
}

.nextButton {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background-color: #EFE7F7;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  border-radius: 5px;
  text-decoration: none;
  font-family: 'Calibri';
  font-size: 18px;
  border: none;
  position: relative;
  margin-top: 30px;
  margin-right: -250px;
  border-radius: 5px;
  padding-bottom: 35px;
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
  height: 35px;
}

.nextButton:hover {
  background-color: #dcd0eb;
  cursor: pointer;
}

input[type="radio"] {
  cursor: pointer;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  font-family: 'Calibri';
  font-size: 19px;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.tab-list-active {
  background-color: #EFE7F7;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  border-radius: 3px;
  font-family: 'Calibri';
}

.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #EFE7F7;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-family: 'Calibri';
}

.quiz {
  font-size: 18px;
  padding-bottom: 30px;
  font-family: 'Calibri';
}

.body-text {
  font-size: 18px;
  border-radius: 3px;
  padding-bottom: 15px;
  width: 500px;
  margin: auto;
  text-align: center;
  font-family: 'Calibri';
}

.homeText {
  width: 470px;
  font-size: 18px;
  font-family: 'Calibri';
}

.makerText {
  width: 470px;
  font-size: 18px;
  font-family: 'Calibri';
}

.homeImage {
  margin-left: 20px;
  margin-top: 20px;
}

.inline-page {
  display: flex;
  justify-content: center;
  font-family: 'Calibri';
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.home-inline-page {
  display: flex;
  justify-content: center;
  font-family: 'Calibri';
  margin-left: 10px;
  margin-right: 10px
}

.image {
  padding-bottom: 15px;
}

.flex-horizontal {
  display: flex;
  flex-direction: row-reverse; 
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.illustrator {
  margin-bottom: 200px !important
}

.inBed {
  margin-bottom: 200px !important
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  border-radius: 5px;
  padding: 15px 20px;
  text-decoration: none;
  font-family: 'Calibri';
  font-size: 20px;
  color: initial;
}

a.button:hover {
  cursor: pointer;
}


.shopButton {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  border-radius: 5px;
  padding: 15px 20px;
  text-decoration: none;
  font-family: 'Calibri';
  font-size: 20px;
  color: initial;
}

.shopButton:hover {
  background-color: #dcd0eb;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mainContent {
  margin-right: 50px;
  margin-left: 50px;
  display: inline-block
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 650px) {
  /* CSS styles for smaller screens */
  .home-inline-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'Calibri';
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 200px;
  }

  .homeImage {
    margin-left: 0px;
    height: 290px;
    width: 300px;
    padding-bottom: 100px;
  }
  
  .homeText {
      width: 470px;
      margin-top: 10px;
      font-size: 18px;
  }

  .makerText {
    width: 470px;
    margin-top: 10px;
    font-size: 18px;
}

  .body-text {
    width: 300px;
  }

  .inBed {
    width: 350px;
    height: 250px
  }



  .shoe {
    break-after: always;
  }

}
